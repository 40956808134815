body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: 'Roboto', san-serif !important;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  cursor: pointer;
}

#root {
  height: auto;
}

  .react-dropdown-select-dropdown {
    z-index: 9999999 !important;
  }

  html {
    box-sizing: border-box;
    font-family: 'Roboto', san-serif;
    min-height: 100vh;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  p,
  label,
  span {
    color: #546e7a;
  }

  #root {
    min-height: 100vh;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Roboto', san-serif;
    color: #29434e;
    color: #1c313a;
  }

  a,
  button {
    cursor: pointer;
  }

  input:active,
  input:focus,
  input:hover,
  input {
    outline: none !important;
  }

  .react-dropdown-select-content span {
    color: rgba(0, 0, 0, 0.87);
  }

  .public-DraftStyleDefault-block {
  margin-bottom: 16px;
}
